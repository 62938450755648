import "core-js/modules/es.error.cause.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-51fb672d"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "invite-panel"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "captcha-wrapper"
};
const _hoisted_4 = {
  class: "img-wrap"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "new-btn-wrap"
};
const _hoisted_7 = {
  class: "top"
};
const _hoisted_8 = {
  class: "center"
};
import { computed, reactive, ref, watch } from 'vue';
import { rules } from '../config/account-config';
import DecorateLine from '@/components/decorate-line';
import useUserStroe from '@/store/modules/user.js';
import { useRouter } from 'vue-router';
import { inviteRegister } from '@/service/invite/invite.js';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'invite-panel',
  props: {
    info: {
      required: true
    },
    inviteCode: {
      required: true
    }
  },

  setup(__props) {
    var _props$info, _props$info2;

    const props = __props;
    const {
      t
    } = useI18n();
    const formRef = ref();
    const userStore = useUserStroe();
    const router = useRouter();
    const form = reactive({
      company: (_props$info = props.info) === null || _props$info === void 0 ? void 0 : _props$info.org,
      email: (_props$info2 = props.info) === null || _props$info2 === void 0 ? void 0 : _props$info2.email,
      username: '',
      pass: '',
      checkPass: '',
      captcha_text: ''
    }); // 密码校验

    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        // 密码长度不能小于5
        callback(new Error(t('register.form_rules.password.length')));
      } else {
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      // 两次输入的密码不一致
      if (value != form.pass) {
        callback(new Error(t('register.form_rules.confirm_password.no_equal')));
      } else {
        callback();
      }
    };

    const formRule = { ...rules,
      pass: [{
        required: true,
        message: t('register.form_rules.password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass,
        trigger: ['blur']
      }],
      checkPass: [{
        required: true,
        message: t('register.form_rules.confirm_password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass2,
        trigger: ['blur']
      }]
    };

    const handleInviteClick = () => {
      var _formRef$value;

      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(valid => {
        if (valid) {
          // 1. 提交表单请求
          handleInvite();
        }
      });
    };

    const handleInvite = () => {
      const data = {
        code: props.inviteCode,
        username: form.username,
        password: form.pass,
        captcha_text: form.captcha_text,
        captcha_code: userStore.image_id
      };
      inviteRegister(data).then(res => {
        if (res.success) {
          // 注册成功
          // 注册成功，3秒后将跳转到登录页面
          ElMessage({
            message: t('invite.invite_success_msg'),
            type: 'success'
          });
          setTimeout(() => {
            location.href = `/login?org=${props.info.org}&app=${props.info.app}&goto=`;
          }, 3000);
        } else {
          userStore.fetchCaptchaImage();
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    }; // 输入密码时才显示验证码


    const isLoadCaptcha = ref(false);
    watch(() => form.pass, (newVal, oldVal) => {
      if (newVal) {
        isLoadCaptcha.value = true;
      }
    });
    watch(isLoadCaptcha, newVal => {
      if (newVal) {
        userStore.fetchCaptchaImage();
      }
    }); // 刷新验证码

    const handleRefreshVerifyImgClick = () => {
      userStore.fetchCaptchaImage();
    };

    const handleToLoginClick = () => {
      router.push('/login');
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(DecorateLine), {
        multiple: 2
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('invite.title')), 1), _createVNode(_component_el_form, {
        model: form,
        ref_key: "formRef",
        ref: formRef,
        "hide-required-asterisk": "",
        "label-position": "left",
        rules: formRule
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.email = $event),
            autocomplete: "off",
            placeholder: _ctx.$t('register.email'),
            disabled: ""
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "username"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.username,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.username = $event),
            autocomplete: "off",
            placeholder: _ctx.$t('register.username'),
            onKeydown: _withKeys(handleInviteClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "pass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.pass,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.pass = $event),
            "show-password": "",
            autocomplete: "off",
            placeholder: _ctx.$t('register.password'),
            onKeydown: _withKeys(handleInviteClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "checkPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: form.checkPass,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.checkPass = $event),
            "show-password": "",
            autocomplete: "off",
            placeholder: _ctx.$t('register.confirm_password'),
            onKeydown: _withKeys(handleInviteClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), isLoadCaptcha.value ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          prop: "captcha_text"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "verifi-box"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.captcha_text,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form.captcha_text = $event),
                placeholder: _ctx.$t('register.captcha_text'),
                onKeydown: _withKeys(handleInviteClick, ["enter"])
              }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
                src: _unref(userStore).image_url,
                alt: "",
                onClick: handleRefreshVerifyImgClick
              }, null, 8, _hoisted_5), _createVNode(_component_el_link, {
                type: "primary",
                onClick: handleRefreshVerifyImgClick
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register.refresh_captcha')), 1)]),
                _: 1
              })])])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
        type: "primary",
        class: "next-btn",
        onClick: handleInviteClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('invite.submit_btn')), 1)]),
        _: 1
      })]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_link, {
        type: "primary",
        class: "login-btn",
        onClick: handleToLoginClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register.has_account')), 1)]),
        _: 1
      })])])]);
    };
  }

};